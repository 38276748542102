.fireworks-container {
	position: relative;
	width: 200px;
	height: 200px;
}

.firework {
	position: absolute;
	width: 10px;
	height: 10px;
	background-color: #fffb00;
	border-radius: 50%;
	animation: explode 3s infinite;
}

@keyframes explode {
	0% {
		opacity: 1;
		transform: scale(1);
	}
	100% {
		opacity: 0;
		transform: scale(2);
	}
}
