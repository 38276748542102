.hr-loyall-login-divider {
	display: flex;
	align-items: center;
	text-align: center;
	color: rgba(255, 255, 255, 0.5);
	font-weight: 400;
	font-size: 8px;
}
.hr-loyall-login-divider::after,
.hr-loyall-login-divider::before {
	content: "";
	border: 0.01em solid rgba(255, 255, 255, 0.5);
	flex: 1;
}
.hr-loyall-login-divider:not(:empty)::before {
	margin-right: 0.25em;
}

.hr-loyall-login-divider:not(:empty)::after {
	margin-left: 0.25em;
}
