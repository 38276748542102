/* RESET STYLE */
.MuiScopedCssBaseline-root input:active,
.MuiScopedCssBaseline-root input:focus,
.MuiScopedCssBaseline-root select:active,
.MuiScopedCssBaseline-root select:focus,
.MuiScopedCssBaseline-root textarea:active,
.MuiScopedCssBaseline-root textarea:focus {
	border-width: 0 !important;
}
.MuiScopedCssBaseline-root label:not(.excludeLabel) {
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	letter-spacing: inherit;
	line-height: inherit;
	text-size-adjust: inherit;
	text-transform: inherit;
}
