.logo-loyall-123 {
	height: 5rem;
	width: 5rem;
	cursor: pointer;
}

.logo-loyall-125 {
	height: 5rem;
	width: 5rem;
	cursor: pointer;
}
